export const news = [
    {
        _id: '001',
        date: '28.07.2024',
        image: 'https://i.postimg.cc/3wN091N8/image-22.jpg',
        name: 'Комплекс работ р.п.Маслянино',
        description: 'Проектирование, сборка и программирование щита управления для ПВУ собственного производства. Поставка кабельно-проводниковой продукции и средств автоматизации на объект. Прокладка силовых и контрольных кабелей. Монтаж и подключение контрольно-измерительных приборов и  исполнительных устройств. Монтаж щита управления, преобразователей частоты и пульта управления Z031. Пусконаладочные работы приточно-вытяжной вентиляционной системы с пластинчатым рекуператором и электронагревом'
    },
    {
        _id: '002',
        date: '29.07.2024',
        image: 'https://i.postimg.cc/3wN091N8/image-22.jpg',
        name: 'Комплекс работ р.п.Маслянино',
        description: 'Проектирование, сборка и программирование щита управления для ПВУ собственного производства. Поставка кабельно-проводниковой продукции и средств автоматизации на объект. Прокладка силовых и контрольных кабелей. Монтаж и подключение контрольно-измерительных приборов и  исполнительных устройств. Монтаж щита управления, преобразователей частоты и пульта управления Z031. Пусконаладочные работы приточно-вытяжной вентиляционной системы с пластинчатым рекуператором и электронагревом'
    },
    {
        _id: '003',
        date: '30.07.2024',
        image: 'https://i.postimg.cc/3wN091N8/image-22.jpg',
        name: 'Комплекс работ р.п.Маслянино',
        description: 'Проектирование, сборка и программирование щита управления для ПВУ собственного производства. Поставка кабельно-проводниковой продукции и средств автоматизации на объект. Прокладка силовых и контрольных кабелей. Монтаж и подключение контрольно-измерительных приборов и  исполнительных устройств. Монтаж щита управления, преобразователей частоты и пульта управления Z031. Пусконаладочные работы приточно-вытяжной вентиляционной системы с пластинчатым рекуператором и электронагревом'
    },
    {
        _id: '004',
        date: '28.07.2024',
        image: 'https://i.postimg.cc/3wN091N8/image-22.jpg',
        name: 'Комплекс работ р.п.Маслянино',
        description: 'Проектирование, сборка и программирование щита управления для ПВУ собственного производства. Поставка кабельно-проводниковой продукции и средств автоматизации на объект. Прокладка силовых и контрольных кабелей. Монтаж и подключение контрольно-измерительных приборов и  исполнительных устройств. Монтаж щита управления, преобразователей частоты и пульта управления Z031. Пусконаладочные работы приточно-вытяжной вентиляционной системы с пластинчатым рекуператором и электронагревом'
    }
]