import Styles from './News.module.css';
import React from 'react';
import { NavLink } from 'react-router-dom';

const News = ({ news }) => {
    return (
        <li className={Styles.news} >
            <NavLink className={Styles.news__block} to={news._id}>
                <span className={Styles.news__date}>{news.date}</span>
                <h3 className={Styles.news__title} >{news.name}</h3>
                <img src={news.image} className={Styles.news__image} />
            </NavLink>
            <line className={Styles.news__line} />
            <ul className={Styles.news__description} >
                {news.description.split('.').map((item, index) => (
                    <li>
                        <p className={Styles.news__text}>{item}</p>
                    </li>
                ))}
            </ul>
            
        </li>
    )
}

export default News; 