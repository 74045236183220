import React from "react";
import InfoItem from "../components/infoItem/InfoItem";
import Location from "../components/location/Location";
import Styles from './ServicesPage.module.css';
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import Company from "../components/company/Company";

import { servicesList } from "../utils/utils";

const ServicesPage = () => {
    const location = [ { link: "/", text: 'Главная /' }, { link: "/services", text: ' Услуги' } ];

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <section className={Styles.page} >
            <Location path={location} />
            <h2 className={Styles.page__title} >УСЛУГИ</h2>
            <ul className={Styles.page__list} >{servicesList.map((item, index) => (<Company title={item.text} key={index} text={item.subtext} />))}</ul>
            <ButtonFeedback />
        </section>
    )
}

export default ServicesPage;