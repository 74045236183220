export const productsObj = {
    controlPanel: [
        {
            _id: '0001',
            image: ['https://i.postimg.cc/cJ4xWGZc/foto1.jpg'],
            price: 'от 24 840',
            name: 'Щит управления ЩУ-ВЕНТп-В-3',
            staticName: 'ЩУ-ВЕНТп-В-3',
            ratedEnginePower: '0,37',
            ratedMotorCurrent: '1...1,6',
            maxPermissibleHeaterPower: '0',
            numberOfHeaterStages: '0',
            description: 'Серия: Щиты для вентиляторов, В; Назначение: управление приточной или вытяжной системой вентиляции без теплообменников, управление приводом заслонки; Принцип управления: Релейная логика; Номинальная мощность электродвигателя: от 0.1 до 15 кВт.',
            characteristic: {
                category: ['Категория', 'Щиты управления'],
                series: ['Серия', 'Щиты для вентиляторов, В'],
                warranty: ['Гарантия', '18 мес.'],
                weight: ['Масса', '5.2 кг'],
                //overallCharacteristics: ['Габариты (ВхШхГ)', '411 мм x 302 мм x 145 мм'],
                overallCharacteristics: ['Габариты (ВхШхГ)', '411 x 302 x 145 мм'],
                volume: ['Объем', '0.022 м3'],
                fabricator: ['Производитель', 'Шрейман-Автоматизация'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                managedEquipment: ['Управляемое оборудование', 'Двигатель вентилятора (прямой пуск/возможность подключения частотного преобразователя), привод воздушной заслонки '],
                housingMaterial: ['Материал корпуса', 'Пластик'],
            },
            engineCharacteristics: {
                phaseAndVoltage: ['Фазность и напряжение', '1х230 В или 3х400 В'],
                currentFrequency: ['Частота тока (номинальная)', '50 Гц'],
                ratedElectricMotor: ['Номинальный ток электродвигателя', 'от 1.0 до 30 А'],
                ratedPowerElectricMotor: ['Номинальная мощность электродвигателя', 'от 0.1 до 15 кВт'],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', 'от 5 до 28 °С'],
            },
            //overallCharacteristics: '411x302x145',
            options: ['', '', ''],
        },
        {
            _id: '0002',
            image: ['https://i.postimg.cc/cJ4xWGZc/foto1.jpg'],
            price: 'от 42 300',
            name: 'Щит управления ЩУ-ВЕНТп-ВТ-1-ЗМ',
            staticName: 'ЩУ-ВЕНТп-ВТ-1-ЗМ',
            ratedEnginePower: '0,37',
            ratedMotorCurrent: '1...1,6',
            maxPermissibleHeaterPower: '0',
            numberOfHeaterStages: '0',
            description: 'Серия: Щиты для приточных систем с водяным нагревателем, ВТ; Назначение: Управление приточной системой вентиляции с водяным нагревателем; Принцип управления: Контроллер; Номинальная мощность электродвигателя: от 0.1 до 15 кВт.',
            characteristic: {
                category: ['Категория', 'Щиты управления'],
                series: ['Серия', 'Щиты для приточных систем с водяным нагревателем, ВТ'],
                warranty: ['Гарантия', '18 мес.'],
                weight: ['Масса', '8.4 кг'],
                overallCharacteristics: ['Габариты (ВхШхГ)', '411 x 302 x 145 мм'],
                volume: ['Объем', '0.029 м3'],
                fabricator: ['Производитель', 'Шрейман-Автоматизация'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                managedEquipment: ['Управляемое оборудование', 'Двигатель вентилятора (прямой пуск/возможность подключения частотного преобразователя), водяной нагреватель, привод воздушной заслонки, контроль фильтра'],
                housingMaterial: ['Материал корпуса', 'Пластик'],
            },
            engineCharacteristics: {
                phaseAndVoltage: ['Фазность и напряжение', '1х230 В или 3х400 В'],
                currentFrequency: ['Частота тока (номинальная)', '50 Гц'],
                ratedElectricMotor: ['Номинальный ток электродвигателя', 'от 1.0 до 30 А'],
                ratedPowerElectricMotor: ['Номинальная мощность электродвигателя', 'от 0.1 до 15 кВт'],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', 'от 5 до 28 °С'],
            },
            overallCharacteristics: '563х302х140',
            options: [''],
        },
        {
            _id: '0003',
            image: ['https://i.postimg.cc/WzrFZcdk/Foto3.jpg', 'https://i.postimg.cc/pdh2rYh9/78621ec1-7f64-49d9-bdd3-20cd2ef844cf.jpg'],
            price: 'от 49 600',
            name: 'Щит управления ЩУ-ВЕНТм-Э-1-ЗМ',
            staticName: 'ЩУ-ВЕНТм-Э-1-ЗМ',
            ratedEnginePower: '0,37',
            ratedMotorCurrent: '1...1,6',
            maxPermissibleHeaterPower: '4,5',
            numberOfHeaterStages: '1',
            description: 'Серия: Щиты для приточных систем с электрическим нагревателем, Э; Назначение: Управление приточной системой вентиляции с водяным нагревателем; Принцип управления: Контроллер; Номинальная мощность электродвигателя: от 0.1 до 15 кВт; Номинальная мощность электрического нагревателя: от 3 до 60 кВт.',
            characteristic: {
                category: ['Категория', 'Щиты управления'],
                series: ['Серия', 'Щиты для приточных систем с электрическим нагревателем, Э'],
                warranty: ['Гарантия', '18 мес.'],
                weight: ['Масса', '21 кг'],
                overallCharacteristics: ['Габариты (ВхШхГ)', '650 x 580 x 220 мм'],
                volume: ['Объем', '0.057 м3'],
                fabricator: ['Производитель', 'Шрейман-Автоматизация'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                managedEquipment: ['Управляемое оборудование', 'Двигатель вентилятора (прямой пуск/возможность подключения частотного преобразователя), Электрический нагреватель, привод воздушной заслонки, контроль фильтра'],
                housingMaterial: ['Материал корпуса', 'Окрашенная сталь'],
            },
            engineCharacteristics: {
                phaseAndVoltage: ['Фазность и напряжение', '1х230 В или 3х400 В'],
                currentFrequency: ['Частота тока (номинальная)', '50 Гц'],
                ratedElectricMotor: ['Номинальный ток электродвигателя', 'от 10 до 130 А'],
                ratedPowerElectricMotor: ['Номинальная мощность электродвигателя', 'от 0.1 до 15 кВт'],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', 'от 5 до 28 °С'],
            },
            overallCharacteristics: '600x400x200',
            options: [''],
        },
        {
            _id: '0004',
            image: ['https://i.postimg.cc/WzrFZcdk/Foto3.jpg','https://i.postimg.cc/pdh2rYh9/78621ec1-7f64-49d9-bdd3-20cd2ef844cf.jpg', 'https://i.postimg.cc/cJ4xWGZc/foto1.jpg'],
            price: 'Цена по запросу',
            name: 'Щит управления ЩУ-НС',
            staticName: 'ЩУ-НС',
            ratedEnginePower: '0,37',
            ratedMotorCurrent: '1...1,6',
            maxPermissibleHeaterPower: '4,5',
            numberOfHeaterStages: '1',
            description: 'Серия: Щиты по Вашему техническому заданию, НС; Назначение: Управление системами автоматизации; Принцип управления: Контроллер, Релейная логика; Номинальная мощность электродвигателя: ---; Номинальная мощность электрического нагревателя: ---.',
            characteristic: {
                category: ['Категория', 'Щиты управления'],
                series: ['Серия', 'Щиты по Вашему техническому заданию, НС'],
                warranty: ['Гарантия', '18 мес.'],
                weight: ['Масса', '---'],
                overallCharacteristics: ['Габариты (ВхШхГ)', '---'],
                volume: ['Объем', '---'],
                fabricator: ['Производитель', 'Шрейман-Автоматизация'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                managedEquipment: ['Управляемое оборудование', 'по Вашему техническому заданию'],
                housingMaterial: ['Материал корпуса', '---'],
            },
            engineCharacteristics: {
                phaseAndVoltage: ['Фазность и напряжение', '---'],
                currentFrequency: ['Частота тока (номинальная)', '---'],
                ratedElectricMotor: ['Номинальный ток управляемого оборудования', '---'],
                ratedPowerElectricMotor: ['Номинальная мощность управляемого оборудования', '---'],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', '---'],
            },
            overallCharacteristics: '600x400x200',
            options: [''],
        },
    ],
    autoDevice: [
        {
            _id: '0001',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Датчик температуры гильзовый TD-G-0-IP54-NTC10k',
            staticName: 'Датчик температуры гильзовый TD-G-0-IP54-NTC10k',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: 'Серия: Датчики температуры TD; Производитель: Вентикс; Тип: гильзовый; Номинальное сопротивление датчика: 10 кОм; Класс защиты: IP54.',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', 'Датчики температуры TD'],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', '0.1 кг'],
                volume: ['Объем', '0.0001 м3'],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', 'гильзовый'],
                nominalResistance: ['Номинальное сопротивление','10кОм'],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', 'до -70 °С'],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0002',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Пульт управления ЩУ',
            staticName: 'Пульт управления ЩУ',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: 'Серия: Пульты дистанционного управления',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', 'Пульты дистанционного управления'],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', '0.3 кг'],
                volume: ['Объем', '0.0005 м3'],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', 'от 5 до 28 °С'],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0003',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '657',
            name: 'Реле перепада давления PS500',
            staticName: 'Реле перепада давления PS500',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: 'Серия: Реле перепада давления PS; Диапазон измерения перепада давления: 0-1500',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', 'Реле перепада давления PS'],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', '0.2 кг'],
                volume: ['Объем', '0.0015 м3'],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', 'до -70 °С'],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0004',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Частотный преобразователь',
            staticName: 'Частотный преобразователь',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: '',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', ''],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', ''],
                volume: ['Объем', ''],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', ''],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0005',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Пульт дистанционного управления ПДУ-БК',
            staticName: 'Пульт дистанционного управления ПДУ-БК',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: '',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', ''],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', ''],
                volume: ['Объем', ''],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', ''],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0006',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Привод воздушной заслонки',
            staticName: 'Привод воздушной заслонки',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: '',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', ''],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', ''],
                volume: ['Объем', ''],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', ''],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0007',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Пульт дистанционного управления ПДУ-К',
            staticName: 'Пульт дистанционного управления ПДУ-К',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: 0,
            description: '',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', ''],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', ''],
                volume: ['Объем', ''],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', ''],
            },
            overallCharacteristics: '',
            options: [],
        },
        {
            _id: '0008',
            image: ['https://i.postimg.cc/1tTgwGHC/auto-Device.jpg', 'https://i.postimg.cc/h46ZMhPN/foto-man.png', 'https://i.postimg.cc/tJxs5svX/regulator.jpg'],
            price: '56 888',
            name: 'Датчик температуры уличный',
            staticName: 'Датчик температуры уличный',
            ratedEnginePower: '',
            ratedMotorCurrent: '',
            maxPermissibleHeaterPower: '',
            numberOfHeaterStages: '0',
            description: '',
            characteristic: {
                category: ['Категория', 'Приборы автоматики'],
                series: ['Серия', ''],
                warranty: ['Гарантия', '24 мес.'],
                weight: ['Масса', ''],
                volume: ['Объем', ''],
                fabricator: ['Производитель', 'Schreiman Engineering'],
                country: ['Страна производства', 'Россия'],
            },
            designCharacteristics: {
                execution: ['Исполнение', 'Общепромышленное'],
                type: ['Тип', ''],
                nominalResistance: ['Номинальное сопротивление', ''],
            },
            climaticCharacteristics: {
                temperature: ['Температура внешней среды', ''],
            },
            overallCharacteristics: '',
            options: [],
        },
    ],
    regulator: [],
    mixUnits: [],
}