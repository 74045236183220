import Styles from '../characteristics/Characteristics.module.css';

const Characteristics = ({product}) => {

    const returnCharacteristic = (obj) => { //возвращает каждую характеристику из определённой категории характеристик
        return Object.values(obj).map((item, index) => {
            return(
                <li className={Styles.detail__item} key={index} >
                    <p className={`${Styles.detail__text} ${Styles.detail__text_name}`} >{item[0]}</p>
                    <p className={Styles.detail__text} >{item[1]}</p>
                </li>
            )
        })
    }

    return(
        <ul className={Styles.detail}  >
            {product.characteristic && <li className={Styles.detail__block} >
                <h2 className={Styles.detail__title} >Характеристики</h2>
                <ul className={Styles.detail__list} >
                    {returnCharacteristic(product.characteristic)}
                </ul>
            </li>}
            {product.designCharacteristics && <li className={Styles.detail__block} >
                <h2 className={Styles.detail__title} >Конструктивные характеристики</h2>
                <ul className={Styles.detail__list} >
                    {returnCharacteristic(product.designCharacteristics)}
                </ul>
            </li>}
            {product.engineCharacteristics && <li className={Styles.detail__block} >
                <h2 className={Styles.detail__title} >Характеристики двигателя</h2>
                <ul className={Styles.detail__list} >
                    {returnCharacteristic(product.engineCharacteristics)}
                </ul>
            </li>}
            { product.climaticCharacteristics && <li className={Styles.detail__block} >
                <h2 className={Styles.detail__title} >Климатические характеристики</h2>
                <ul className={Styles.detail__list} >
                    {returnCharacteristic(product.climaticCharacteristics)}
                </ul>
            </li>}
        </ul>
    )
}

export default Characteristics;