import React from "react";
import Styles from "./ItemNewsPage.module.css";
import Location from "../components/location/Location";
import { useLocation } from "react-router-dom";
import { news } from "../utils/news";

const ItemNewsPage = () => {
    const locationLinks = [ { link: "/", text: 'Главная /' }, { link: "/news", text: ' Новости' } ];
    const location = useLocation();
    const item = news.find(item => item._id === location.pathname.slice(-3));

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <section className={Styles.page} >
            <Location path={locationLinks} />
            <h2 className={Styles.title} >{item.name}</h2>
            <span className={Styles.date} >{item.date}</span>
            <img className={Styles.img} src={item.image} />
            <ul className={Styles.list} >
                {item.description.split('.').map((item, index) => (
                    <li key={index}>
                        <p className={Styles.text}>- {item}</p>
                    </li>
                ))}
            </ul>
        </section>
    )
};

export default ItemNewsPage;