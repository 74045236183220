import React, { useState } from "react";
import Styles from './Product.module.css';
import { useSelector } from 'react-redux';
import Option from '../option/Option';
import OrderPopup from "../orderPopup/OrderPopup";
import NextButton from '../../images/иконки/next.svg';
import Popup from "../popup/Popup";
import Characteristics from "../characteristics/Characteristics";
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import Loading from '../loading/Loading';

const Product = ({ product }) => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [show, setShow] = React.useState(false);
    const [ showImg, setShowImg] = useState(false);
    const [selectImg, setSelectImg] = React.useState(0);
    const products = useSelector( store => store.products.products );
    setTimeout(() => setLoading(false), 3000); // небольшая загрузка

    const showPopup = () => setShow(!show);
    const showPopupImg = () => setShowImg(!showImg); 

    const nextPhoto = () => (selectImg < (product.image.length - 1)) && setSelectImg(selectImg + 1);
    const prevPhoto = () => (selectImg > 0) && setSelectImg(selectImg - 1);

    const returnOptions = () => {  //ищем опции товара среди остальных товаров
        let arr = [];
        product.options.map(name => {
            for (var key in products) {
                products[key].map(item => {
                    return item.name === name && arr.push(item);
                });
            };
        });
        return arr.map(item => {
            return (<Option key={item._id} el={item} name={item.name} description={item.description} inPopup={show} price={item.price} />)
        });
    }

    const returnPhotoProduct = () => { // отприсовка блока с фоторафиями товара
        return (
            <div className={Styles.description__imagesBlock}>
                <div className={Styles.description__imageWrap}>
                    <img src={product.image[selectImg]} alt="фотография товара" className={Styles.description__image} onClick={showPopupImg} />
                    <button className={`${Styles.description__switchButton} ${Styles.description__switchButton_route_right}`} onClick={prevPhoto} ><img src={NextButton} alt="кнопка переключения на предыдущую фотографию" /></button>
                    <button className={Styles.description__switchButton} ><img src={NextButton} onClick={nextPhoto} alt="кнопка переключения на следующую фотографию" /></button>
                </div>
                <ul className={Styles.description__imagesList}>
                    {product.image.map((item, index) => {
                        return (
                            <li key={index}>
                                <img src={item} className={`${Styles.description__imageItem} ${(selectImg === index) && Styles.description__imageItem_checked}`} alt="фотография товара" onClick={() => setSelectImg(index)} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    return (
        <section className={Styles.product} >
            <h2 className={Styles.product__title} id="detail">{product.name}</h2>
            {/*<nav className={Styles.product__navigation}>
                <Link className={Styles.product__link} to={`${location.pathname}#detail`}>Общая информация</Link>
                <Link className={Styles.product__link} to={`${location.pathname}#characteristics`}>Характиристики</Link>
                <Link className={Styles.product__link} to={`${location.pathname}#option`}>Опции</Link>
            </nav>*/}
            <div className={Styles.description}>
                {returnPhotoProduct()}
                <div className={Styles.description__info}>
                    <div className={Styles.description__textBlock} >
                        <p className={Styles.description__text} >Арт. {product._id}</p>
                        <p className={Styles.description__text} >{product.description.replace(/;/g, "\n")}</p>
                    </div>
                    <div className={Styles.description__order} >
                        <p className={Styles.description__price} >{product.price} р.</p>
                        {/*<button type='button' className={Styles.description__button} onClick={showPopup} >Добавить</button>*/}
                    </div>
                </div>
            </div>
            <div id="characteristics">
                <Characteristics product={product}/>
            </div>
            {/*product.options.length !== 0 && <div className={Styles.option} id="option" >
                <h3 className={Styles.option__title} >Опции</h3>
                <ul className={Styles.option__list} >
                    {product && returnOptions()}
                </ul>
            </div>*/}
            <OrderPopup show={show} closePopup={showPopup} product={product} />
            <Popup show={showImg} closePopup={showPopupImg}>
                <div className={Styles.popupWrap}>
                <img className={Styles.popupImg} src={product.image[selectImg]} alt="фотография товара" />
                <button className={`${Styles.description__switchButton} ${Styles.description__switchButton_route_right} ${Styles.description__switchButton_place_popup}`} onClick={prevPhoto} ><img className={Styles.switchImg} src={NextButton} alt="кнопка переключения на предыдущую фотографию"/></button>
                <button className={`${Styles.description__switchButton} ${Styles.description__switchButton_place_popup}`} ><img className={Styles.switchImg} src={NextButton} alt="кнопка переключения на следующую фотографию" onClick={nextPhoto} /></button>
                </div>
            </Popup>
            <div className={!loading && Styles.loading_view_none}><Loading /></div>
        </section>
    )
}

export default Product;