import Styles from './Footer.module.css';
//Иконки месседжеров
import WhatsApp from '../../images/иконки/icon-whatsapp.svg';
import Telegram from '../../images/иконки/icon-telegram.svg';

const Footer = () => {
    return (
        <footer className={Styles.footer}>
            <div className={Styles.footer__wrapper}>
                <p className={`${Styles.footer__text} ${Styles.footer__text_place_last}`}>с 2032. Все права защищены. Политика конфидициальности</p>
                <div className={Styles.footer__contacts}>
                    <p className={Styles.footer__contacts_text}>+7 (913) 775-47-95</p> {/* На этой строчке можно изменить номер телефона компании */}
                    <p className={Styles.footer__contacts_text}>zakaz@schreiman-engineering.ru</p> {/* На этой строчке можно изменить электронную почту компании */}
                </div>
                <p className={`${Styles.footer__text} ${Styles.footer__text_place_before}`}>Адрес: г. Новосибирск, <br /> ул. Королева д.40 производственный корпус 61 офис 44</p> {/* На этой строчке можно изменить адрес компании */}
                <div className={Styles.social}>
                    {/* Блок в подвале с ссылками на мессенджеры: WhatsApp и Telegram. Сейчас ссылки указывают на Сашин аккаунт, чтоб это изменить требуется:
                        1. Взять ссылку нужного контакта через приложение или сайт месседжера
                        2. Вставить эту ссылку в атрибут href='ссылка'
                    */}
                    <a href='https://wa.me/79137754795?text=' ><img src={WhatsApp} className={Styles.social__link} /></a>
                    <a href="https://t.me/Germech"><img src={Telegram} className={Styles.social__link} /></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;