import step1 from "../images/иконки/work/1step.png";
import step2 from "../images/иконки/work/2step.png";
import step3 from "../images/иконки/work/3step.png";
import step4 from "../images/иконки/work/4step.png";
import step5 from "../images/иконки/work/5step.png";
import step6 from "../images/иконки/work/6step.png";

export const servicesList = [
    {
        text: "Производство щитов управления",
        subtext: "Разработаем системы управления любого уровня сложности. Предусмотрим необходимый функционал и обеспечим эффективный алгоритм работы",
        id: 1,
    },
    {
        text: "Поставка автоматики",
        subtext: "Выполним подбор необходимых устройств, основываясь на Ваших требованиях, бюджете и желаемых сроках",
        id: 2,
    },
    {
        text: "Пусконаладочные работы",
        subtext: "Проведем полный комплекс технологических мероприятий для качественного и безопасного запуска исполнительных устройств",
        id: 3,
    },
    {
        text: "Монтажные работы",
        subtext: "Произведем монтаж и подключение щитов управления, частотных преобразователей, электроприводов, датчиков и других исполнительных механизмов. Проведем полное руководство монтажом оборудования автоматики (шеф-монтаж)",
        id: 4,
    },
    {
        text: "Сервисное обслуживание автоматики",
        subtext: "Выполним качественное обслуживание вашей системы для дальнейшей безаварийной работы. Заключим договор на долгосрочное сотрудничество",
        id: 5,
    },
    {
        text: "Программирование логических контроллеров",
        subtext: "Разработаем и внедрим программное решение для имеющихся у клиента щитов управления.",
        id: 6,
    }
]

export const advantagesList = [
    {
        text: '7 лет',
        subtext: 'Занимаемся комплексной автоматизацией технологических процессов',
        id: '001'
    },
    {
        text: '30 минут',
        subtext: 'Именно столько займет ожидание первичной обратной связи от нас',
        id: '002'
    },
    {
        text: '1 день',
        subtext: 'И коммерческое предложение с наиболее подходящим решением у Вас на рассмотрении',
        id: '003'
    },
    {
        text: 'Под ключ',
        subtext: 'Реализуем проекты любого уровня без лишних забот для клиента',
        id: '004'
    },
    {
        text: 'на 15%',
        subtext: 'Цены ниже рыночных благодаря сотрудничеству с крупными поставщиками и оптимизированным производственным процессам',
        id: '005'
    },
    {
        text: '18 месяцев',
        subtext: 'Гарантия на наши щиты управления. Щиты сертифицированы и соответствуют всем стандартам, поэтому мы уверены в их качестве',
        id: '006'
    },
]

export const workStepsList = [
    {
        name: "Оформление заявки",
        img: step1,
        description: "Вы оставляете заявку на почте или по телефону"
    },
    {
        name: "Подтверждение заявки",
        img: step2,
        description: "Менеджер связывается с Вами для уточнения деталей и подтверждения заказа"
    },
    {
        name: "Выдаем решение",
        img: step3,
        description: "Менеджер удобным для Вас способом передает Вам готовое решение с ценами и подробной информацией"
    },
    {
        name: "Заключение договора и оплата",
        img: step4,
        description: "Заключаем с Вами договор, после чего Вы производите оплату"
    },
    {
        name: "Производство и монтаж",
        img: step5,
        description: "Производим щит и выполняем монтажные работы"
    },
    {
        name: "Сдача работ клиенту",
        img: step6,
        description: "Доставляем оборудование и сдаем Вам работы"
    },
]

export const checkItem = ( arr, item ) => {
    const index = arr.findIndex( el => el.name == item.name );
    return ( ( index > -1 ) ? arr.map( el => (( el.name == item.name ) ? { ...el, count: el.count + item.count } : el )) : [...arr, item] );
}