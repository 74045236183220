import { NavLink, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from 'react-redux';

import Styles from './ProductsList.module.css';
import IconList from '../../images/иконки/Vector_list.svg';
import OrderPopup from "../orderPopup/OrderPopup";
import IconTable from '../../images/иконки/Vector_table.svg';
import Loading from '../loading/Loading';

const ProductsList = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    let title;
    let link;
    const [show, setShow] = React.useState(false);
    const [product, setProduct] = React.useState({});
    const [startCard, setSartCard] = React.useState(0);
    const [typeCard, setTypeCard] = React.useState('list');
    const [checked, setChecked] = useState([]);
    const products = useSelector( store => store.products.products );
    setTimeout(() => setLoading(false), 3000);

    function chengeCheckbox(value) { // работа фильтрации товаров по выбранным категориям
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if(currentIndex === -1){
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }

    const showPopup = (item) => {
        setShow(true);
        setProduct(item)
    };
    const closePopup = () => setShow(false);

    const getProductsArr = () => {
        switch (location.pathname) {
            case '/catalog/controlPanel': {
                title = 'Щиты управления';
                link = '/catalog/controlPanel';
                return products.controlPanel
            }
            case '/catalog/autoDevice': {
                title = 'Приборы автоматики';
                link = '/catalog/autoDevice';
                return products.autoDevice
            }
            case '/catalog/regulator': {
                title = 'Регуляторы';
                link = '/catalog/regulator';
                return products.regulator
            }
            case '/catalog/mixUnits': {
                title = 'Сместительные узлы';
                link = '/catalog/mixUnits';
                return products.mixUnits
            }
            default: {
                return [];
            }
        }
    };

    const ProductCards = getProductsArr().slice(0,startCard);
    const filterCards = () => {
        let productReturn = [];
        for(let i=0; i < checked.length; i++) {
            let product = getProductsArr().filter(item => item.characteristic.series[1] === checked[i]);
            productReturn = productReturn.concat(product);
        }
        return productReturn;
    }

    const details = [...new Set(getProductsArr().map( item => item.characteristic.series[1] ))];

    function showsnumberList() {
        if( window.innerWidth < 768 ) {
          return setSartCard(5)
        } if (window.innerWidth >= 768) {
          return setSartCard(8) }
    };
  
    React.useEffect(() => {
        showsnumberList();
        window.innerWidth < 768 && setTypeCard('table');
    }, [window.innerWidth]);

    function handleMore() {
        if( window.innerWidth < 768 ) {
          return setSartCard(startCard + 5)
        } if (window.innerWidth >= 768) {
          return setSartCard(startCard + 12)
        }
    }

    function hideButton() {
        if ((getProductsArr().length === ProductCards.length) || (checked.length !== 0)) {
          return true
        } else {
          return false
        }
    }

    const returnProduct = (cards) => {
        return cards.map((item) => {
                return (
                    <li key={item._id} className={`${Styles.item} ${typeCard === 'list' && Styles.item_type_list}`}>
                        <img className={`${Styles.item__image} ${typeCard === 'list' && Styles.item__image_type_list}`} src={item.image[0]} alt="фотография товара" />
                        <NavLink className={`${Styles.item__description} ${typeCard === 'list' && Styles.item__description_type_list}`} to={`${link}/${item._id}`}>
                            <h3 className={`${Styles.item__name} ${typeCard === 'list' && Styles.item__name_type_list}`} >{item.name}</h3>
                            <p className={`${Styles.item__text} ${typeCard === 'list' && Styles.item__text_type_list}`} >{item.description}</p>
                            <p className={`${Styles.item__text}, ${Styles.item__text_type_last} ${typeCard === 'list' && `${Styles.item__text_type_list} ${Styles.item__text_position}`}`} >Арт. {item._id}</p>
                        </NavLink>
                        <div className={`${Styles.item__order} ${typeCard === 'list' && Styles.item__order_type_list}`} >
                            <p className={`${Styles.item__price} ${typeCard === 'list' && Styles.item__price_type_list}`} >{item.price} р.</p>
                            {/*<p className={`${Styles.item__text} ${Styles.item__text_display} ${typeCard === 'list' && Styles.item__text_type_list}`} >на заказ</p>
                            <button type='button' className={`${Styles.item__button} ${typeCard === 'list' && Styles.item__button_type_list}`} onClick={() => showPopup(item)} >Добавить</button>*/}
                        </div>
                    </li>
                )
            })
    }

    return (
        <section className={Styles.catalog}>
            <h2 className={Styles.catalog__title}>{title}</h2>
            <div className={Styles.version}>
                <h3 className={Styles.version__title}>Автоматика</h3>
                <ul className={Styles.version__list}>
                    {details.map( (item, index) => {
                        return (
                            <label htmlFor={index} className={Styles.vertion__label} key={index}>
                                <input
                                    key={index} 
                                    type="checkbox" 
                                    id={index} 
                                    value={item} 
                                    className={Styles.version__item}  
                                    onChange={() => chengeCheckbox(item)} />
                                <p className={Styles.version__text}>{item}</p>
                            </label>
                        )
                    } )}
                </ul>
            </div>
            <div className={`${Styles.catalog__market}`}>
                {(window.innerWidth > 768 ) && <div className={`${Styles.catalog__buttons} ${typeCard === 'list' && Styles.catalog__buttons_type_list}`}>
                    <p className={Styles.catalog__type}>Варианты отображения:</p>
                    <button className={Styles.catalog__button}><img className={Styles.catalog__ikon} src={IconList} alt="кнопка переключения на режим листа" onClick={() => setTypeCard('list')} /></button>
                    <button className={Styles.catalog__button} ><img className={Styles.catalog__ikon} src={IconTable} alt="кнопка переключения на режим таблицы" onClick={() => setTypeCard('table')}/></button>
                </div>}
                <ul className={`${Styles.list} ${typeCard === 'list' && Styles.list_type_list}`}>
                    {(checked.length === 0) ? returnProduct(ProductCards) : returnProduct(filterCards())}
                </ul>
            </div>
            {!hideButton() && <button className={Styles.buttonMore} onClick={handleMore} >Показать ещё</button>}
            { show && <OrderPopup show={show} closePopup={closePopup} product={product} />}
            <div className={!loading && Styles.loading_view_none}><Loading /></div>
        </section>
    )
}

export default ProductsList;