import Styles from './OrderPopup.module.css';
import Popup from '../popup/Popup';
import React from 'react';

import { useDispatch, useSelector, } from 'react-redux';
import { postOrderSuccess } from '../../services/actions/actions';
import { NavLink, } from "react-router-dom";
import Option from "../option/Option";

import Plus from '../../images/иконки/4105962-add-expand-plus_113920.svg';
import Minus from '../../images/иконки/4105964-collapse-minus-reduce-remove_113927.svg';

const OrderPopup = ({ show, closePopup, product }) => {
    const dispatch = useDispatch();
    const products = useSelector( store => store.products.products );
    const selectOptions = useSelector( store => store.products.product );
    const [ count, setCount ] = React.useState(1);
    let katecory;

    for (var key in products) { //с помощью перебора всех продуктов находим нужную категорию, для ссылки товара на его страницу
        products[key].map(item => {
            return item.name === product.name && (katecory = key);
        });
    };

    const returnOptions = () => { // отрисовка опций товара
        let arr = [];
        product.options.map(name => {
            for (var key in products) {
                products[key].map(item => {
                    return item.name === name && arr.push({ ...item, katecory: key});
                });
            };
        });
        return arr.map(item => {
            return (<Option key={item._id} el={item} name={item.name} description={item.description} inPopup={show} price={item.price} />)
        });
    }

    //добавить функцию закрытия попапа, где используется закрытие попапа и очистка массива опций

    const pushOrder = () => {
        dispatch(postOrderSuccess({...product, count: count}));
        selectOptions.forEach(el => {
            dispatch(postOrderSuccess({...el, count: 1}));
        });
        closePopup();
    }

    return (
        <Popup show={show} closePopup={closePopup}>
            <div className={Styles.item}>
                    <NavLink to={`/catalog/${katecory}/${product._id}`} className={Styles.imageBlock} ><img className={Styles.item__image} src={product.image[0]} alt='Фотография товара' /></NavLink>
                    <NavLink to={`/catalog/${katecory}/${product._id}`} className={Styles.item__name}><h3>Щит управления {product.name}</h3></NavLink>
                    <div className={Styles.item__quantity}>
                        <p className={Styles.item__price} >{product.price}</p>
                        <div className={Styles.item__countBlock}>
                            <button className={Styles.item__count} onClick={() => setCount(count-1)} disabled={ count === 1 ? true : false }><img className={Styles.item__count_img} src={Minus} alt='Иконка кнопки минус'/></button>
                            <p className={Styles.item__value} >{count}</p>
                            <button className={Styles.item__count} onClick={() => setCount(count+1)}><img className={Styles.item__count_img} src={Plus} alt='Иконка кнопки плюс'/></button>
                        </div>
                        <p className={Styles.item__price} >{(parseInt(Number(product.price.replace(/\D/g, "")) * count)).toLocaleString('ru-Ru')}</p>
                    </div>
                </div>
                <button type='button' className={Styles.item__button} onClick={pushOrder} >Добавить</button>
                {!(product.options.length === 0) && <div className={Styles.option}>
                    <h3 className={Styles.option__title} >Добавьте опции в комплект:</h3>
                    <ul className={Styles.option__list} >
                        {product && returnOptions()}
                    </ul>
                </div>}
        </Popup>
    )
};


export default OrderPopup;