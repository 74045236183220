import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Styles from './App.module.css';

import AppHeader from '../appHeader/AppHeader';
import Footer from '../footer/Footer';

import { 
  HomePage, 
  ServicesPage, 
  CompanyPage, 
  NewsPage, 
  CatalogPage, 
  ProductsListPage, 
  ItemPage, 
  BasketPage, 
  NotFoundPage, 
  ContactsPage,
  ItemNewsPage,
} from '../../pages/index';

const App = () => {

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className={Styles.app}>
      <AppHeader></AppHeader>
      <Routes>
        <Route path="/" element={ <HomePage /> }/>
        {/*<Route path="/company" element={ <CompanyPage /> } />*/}
        <Route path="/services" element={ <ServicesPage /> } />
        <Route path="/news" element={ <NewsPage /> } />
        <Route path="/news/:news" element={ <ItemNewsPage /> } />
        <Route path="/contacts" element={ <ContactsPage /> } />
        <Route path="/basket" element={ <BasketPage /> } />

        <Route path="/catalog" element={ <CatalogPage /> } />
        <Route path="/catalog/:products" element={ <ProductsListPage /> } />

        <Route path="/catalog/:products/:itemId" element={ <ItemPage /> } />
        <Route path='*' element={ <NotFoundPage /> } />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
