import Styles from './BasketPage.module.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Del from '../images/иконки/bin_delete_file_garbage_recycle_remove_trash_icon_123192.svg';
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import { postOrderFailed } from '../services/actions/actions';
import Location from '../components/location/Location';
import BasketItem from '../components/basketItem/BasketItem';
import Popup from '../components/popup/Popup';
import Form from '../components/form/Form';
import Loading from '../components/loading/Loading';

const BasketPage = () => {
    
    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [loading, setLoading] = React.useState(true);
    let date = new Date();
    const [ showForm, setShowForm] = React.useState(false);

    const onClickButton = () => setShowForm(true);
    const onClickClose = () => setShowForm(false);
    const locationLinks = [ 
        { link: "/", text: 'Главная /' }, 
        { link: "/basket", text: ' Корзина' },
    ];
    const dispatch = useDispatch();
    const order = useSelector( store => store.order.order );
    setTimeout(() => setLoading(false), 3000);

    const clickButton = () => {
        dispatch(postOrderFailed());
    }

    const returnSumm = () => {
        let sum = 0;
        order.forEach(element => {
            sum = sum + parseInt(Number(element.price.replace(/\D/g, "")) * (element.count ? element.count : 1));
        });
        return sum.toLocaleString('ru-Ru');
    }

    const returnWeight = () => {
        let sum = 0;
        order.forEach(element => {
            sum = sum + Number(element.characteristic.weight[1].replace(/[^\d.]/ig, ""));
        });
        return sum;
    }

    const returnVolume = () => {
        let sum = 0;
        order.forEach(element => {
            sum = sum + Number(element.characteristic.volume[1].replace(/[^\d.]/ig, ""));
        });
        return sum;
    }

    const returnCount = () => {
        let sum = 0;
        order.forEach(element => {
            sum = sum + (element.count ? element.count : 1);
        });
        return sum;
    }

    const returnProduct = () => {
        return order.map((item, index) => {
            return (
                <BasketItem product={item} key={index} />
            )
        })
    }

    return (
        <section className={Styles.page}>
            <Location path={locationLinks} />
            <h2 className={Styles.basket__title}>Корзина</h2>
            <div className={Styles.basket}>
                <div className={Styles.basket__info}>
                    <p className={Styles.basket__subtitle} >Цены и сроки рассчитаны для отгрузки из филиала Новосибирск.</p>
                    <button className={Styles.basket__delButten}><img src={Del} alt="кнопка для очистки корзины" onClick={clickButton} /></button>
                </div>
                <ul className={Styles.basket__list}>
                    {returnProduct()}
                </ul>
                <div className={Styles.summe}>
                    <h3 className={Styles.summe__title}>Ваш заказ:</h3>
                    <p className={Styles.summe__price}>{returnSumm()} p.</p>
                    <ul className={Styles.summe__list}>
                        <li className={Styles.characteristic} >
                            <p className={Styles.characteristic__text_margin_none}>Всего товаров:</p>
                            <p className={Styles.characteristic__text_margin_none}>{returnCount()} шт.</p>
                        </li>
                        <li className={Styles.characteristic}>
                            <p className={Styles.characteristic__text_margin_none}>Вес:</p>
                            <p className={Styles.characteristic__text_margin_none}>{returnWeight()} кг.</p>
                        </li>
                        <li className={Styles.characteristic}>
                            <p className={Styles.characteristic__text_margin_none}>Объем:</p>
                            <p className={Styles.characteristic__text_margin_none}>{returnVolume()} м³</p>
                        </li>
                        <li className={Styles.characteristic}>
                            <p className={Styles.characteristic__text_margin_none}>Самовывоз</p>
                        </li>
                        <li className={Styles.characteristic}>
                            <p className={Styles.characteristic__text_margin_none}>Адрес:</p>
                            <p className={`${Styles.characteristic__text_margin_none} ${Styles.characteristic__text_adress}`}>Новосибирск, ул. Выборная, д. 141</p>
                        </li>
                        <li className={Styles.characteristic}>
                            <p className={Styles.characteristic__text_margin_none}>Дата ближайшей отгрузки:</p>
                            <p className={Styles.characteristic__text_margin_none}>{date.getDate() + 3}.0{date.getMonth() + 1}.{date.getFullYear()}</p>
                        </li>
                    </ul>
                    <span className={Styles.summe__subtext} >Цены и сроки рассчитаны на дату и время обновления страницы и являются предварительными.</span>
                    <button className={Styles.summe__subButton} onClick={onClickButton}>Оформить заказ</button>
                </div>
            <iframe 
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A0710a2264b82f3f17fc3176e27f1189e376689b59109e2b6041ab86e379d6df1&amp;source=constructor" 
                width="400" 
                height="346" 
                frameBorder="0"
                className={Styles.basket__map}
            />
            </div>
            <ButtonFeedback />
            <Popup show={showForm} closePopup={onClickClose} >
                <Form />
            </Popup>
            <div className={!loading && Styles.loading_view_none}><Loading /></div>
        </section>
    )
}

export default BasketPage;