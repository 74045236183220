import Styles from './Navigation.module.css';
import { NavLink } from "react-router-dom";
//Иконка корзины
import Basket from '../../images/иконки/basket_icon-icons.com_67985 (1) 1.svg';

const Navigaiton = ({ show, close }) => {
    //const onclick = () => close(false); 

    return (
        <nav className={show ? Styles.navigate : Styles.burgerNavigation}>
            {/* Здесь можно поменять местами ссылки в шапке.
                Важно! менять  не только слово-название, но и саму ссылку в атрибуте to='' 
            */}
            <NavLink to='/' className={Styles.navigate__link} onClick={close} >Главная</NavLink>
            <NavLink to='/catalog' className={Styles.navigate__link} onClick={close} >Продукция</NavLink>
            <NavLink to='/services' className={Styles.navigate__link} onClick={close} >Услуги</NavLink>
            <NavLink to='/news' className={Styles.navigate__link} onClick={close} >Новости</NavLink>
            <NavLink to='/contacts' className={Styles.navigate__link}onClick={close} >Контакты</NavLink>
            {/*<button className={Styles.navigate__button}>
                <img className={Styles.navigate__basket} src={Basket} alt='Иконка корзины' />
                <NavLink to='/basket' className={Styles.navigate__link} >Корзина</NavLink>
            </button>*/}
        </nav>
    )
}

export default Navigaiton;