import Styles from './Form.module.css';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Delete from "../../images/иконки/remove.svg";

const Form = () => {
    const [ files, setFiles ] = useState(null);
    const [send, setSend] = useState(false);

    const handleChange = (event) => {
        const files = event.target.files;
        setFiles([...files]);
    }

    const deleteFile = (name) => {
        const items = files.filter(item => item.name !== name );
        setFiles(items);
        console.log(files);
    }

    async function formSend(e) {  //отправка данных на почту
        e.preventDefault();
        setSend(true);

        try {
            // Формируем запрос
          const response = await fetch("send.php", {
              method: 'POST',
              body: new FormData(e.target)
          });
          // проверяем, что ответ есть
          if (!response.ok) throw (`Ошибка при обращении к серверу: ${response.status}`);
          // проверяем, что ответ действительно JSON
          const contentType = response.headers.get('content-type');
          if (!contentType || !contentType.includes('application/json')) {
            throw ('Ошибка обработки. Ответ не JSON');
          }
          // обрабатываем запрос
          const json = await response.json();
          if (json.result === "success") {
              // в случае успеха
              console.log(json.info);
          } else { 
              // в случае ошибки
              console.log(json);
              throw (json.info);
          }
        } catch (error) { // обработка ошибки
          console.log(error);
        }
    }

    const returnForm = () => {
        return (
            <>
            <h3 className={Styles.form__title}>Оставьте заявку ниже</h3>
            <fieldset className={Styles.form__inputList}>
                <input required className={Styles.form__input} id='name' placeholder='Ваше имя' name="name" type="text"></input>
                <input required className={Styles.form__input} id='email' placeholder='Номер телефона или E-mail' name="email" type="text"></input>
                <input className={`${Styles.form__input} ${Styles.form__input_type_massage}`} id='text' placeholder='Ваш вопрос или сообщение' name="text" txt="text"></input>
                <div className={Styles.documentsBlock}>
                    <p className={Styles.documentsBlock__title}>Ваши файлы</p>
                    <p className={`${Styles.documentsBlock__title} ${Styles.documentsBlock__title_type_span} `}>Один файл не должен превышать 2МБ</p>
                    <label className={Styles.label}>
                        <input type='file' name="myfile[]" multiple id="myfile" className={Styles.documentsBlock__input} onChange={handleChange} />
                        <button className={Styles.documentsBlock__addButton} >Добавить файл</button>
                    </label>
                    <div className={Styles.documentsBlock__block}>
                        { files !== null && files.map((item, index) => <div className={Styles.documentsBlock__file} key={index} >
                            {item.size > 2 * 1024 * 1024 ? <p className={Styles.file__text}>Файл {item.name} больше 2МБ</p> : <p className={Styles.file__text}>{item.name}</p>}
                            <img className={Styles.file__del} src={Delete} onClick={() => deleteFile(item.name)} />
                        </div>) }
                    </div>
                </div>
            </fieldset>
            <button className={Styles.form__button} type="submit">ОТПРАВИТЬ ЗАПРОС</button>
            <p className={Styles.form__subtext}>Нажимая кнопку, вы соглашаетесь <br /> с Политикой конфендициальности</p>
            </>
        )
    }

    return (
        <form className={Styles.form} onSubmit={formSend} encType="multipart/form-data" method="post" id="form" action="send.php">
            {send ? <div className={Styles.popup}><p className={Styles.popup__text}>Спасибо! <br/> Мы свяжемся с вами</p></div> : returnForm()}
        </form>
    )
}

export default Form;