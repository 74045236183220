import Styles from './Address.module.css';

const Address = () => {
    return (
        <section className={Styles.address}>
            <h3 className={Styles.address__title}>Основная информация</h3>
            <div className={Styles.contacts}>
                <p className={Styles.contacts__text}>Адрес: г. Новосибирск, ул. Королева д.40 производственный корпус 61 офис 44</p> {/* На этой строчке можно изменить адрес компании */}
                <p className={Styles.contacts__text}>Телефон: + 7 (913) 486-12-14</p> {/* На этой строчке можно изменить номер телефона компании */}
                {/* Ниже расположен фрейм от ЯндексКарт с меткой на ваш офис. Менять стоит только строчку с ссылкой -- src (так как остальные строчки связаны со стилями) */}
                <iframe 
                    src="https://yandex.ru/map-widget/v1/?um=constructor%3A0710a2264b82f3f17fc3176e27f1189e376689b59109e2b6041ab86e379d6df1&amp;source=constructor" 
                    width="900" 
                    height="391" 
                    frameBorder="0"
                    className={Styles.contacts__map}
                />
            </div>
        </section>
    )
};

export default Address;