import { NavLink } from "react-router-dom";

import Styles from './Catalog.module.css';
//чтоб изменить картинку категории достаточно изменить путь до фотографии ниже.
//Новые фотографии не советую размещать в другие папки, так и путь особо менять не потребуется (только название файла)
import controlPanel from '../../images/категории/contrlPanel.jpg';
import autoDevice from '../../images/категории/autoDevice.jpg';
import regulator from '../../images/категории/regulator.jpg';
import mixUnits from '../../images/категории/b1b15fa5ac94a6f64889009c37b2515d-1000x1000.jpg';

const CatalogItems = () => {
    return (
        <section className={Styles.catalog}>
            <h2 className={Styles.catalog__title}>ПРОДУКЦИЯ</h2>
            <nav className={Styles.catalog__items}>
                <NavLink to="/catalog/controlPanel" className={Styles.itemPr}>
                    <img className={Styles.itemPr__image} src={controlPanel} alt="Картинка: Щиты управления" />
                    <p className={Styles.itemPr__name}>Щиты управления</p> {/* Можно поменять и название категории */}
                </NavLink>
                <NavLink to="/catalog/autoDevice" className={Styles.itemPr}>
                    <img className={Styles.itemPr__image} src={autoDevice} alt="Картинка: Приборы автоматики" />
                    <p className={Styles.itemPr__name}>Приборы автоматики</p> {/* Можно поменять и название категории */}
                </NavLink>
                <NavLink to="/catalog/regulator" className={Styles.itemPr}>
                    <img className={Styles.itemPr__image} src={regulator} alt="Картинка: Регуляторы" />
                    <p className={Styles.itemPr__name}>Регуляторы</p> {/* Можно поменять и название категории */}
                </NavLink>
                {/*
                <NavLink to="/catalog/mixUnits" className={Styles.itemPr}>
                    <img className={Styles.itemPr__image} src={mixUnits} alt="Картинка: Смесительные узлы" />
                    <p className={Styles.itemPr__name}>Смесительные узлы</p>
                </NavLink>
                */}
            </nav>
        </section>
    )
}

export default CatalogItems;