import Styles from "./StepsWork.module.css";
import React from "react";

import { workStepsList } from "../../utils/utils";

const StepsWork = () => {
    const returnStep = (item, key) => (
        <div className={Styles.step} key={key}>
            <img src={item.img} className={Styles.step__img} />
            <p className={Styles.step__name} >{item.name}</p>
            <p className={Styles.step__description} >{item.description}</p>
        </div>
    );

    return (
        <section className={Styles.work}>
            <h2 className={Styles.work__title} >Как мы работаем</h2>
            <ul className={Styles.work__stepsList} >{workStepsList.map((item, key) => (
                <li className={Styles.work__step} >
                    {returnStep(item, key)}
                </li>
            ))}</ul>
        </section>
    )
};

export default StepsWork;