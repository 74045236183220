import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Styles from './AppHeader.module.css';
import Navigaiton from "../navigation/Navigation";
//импорт иконок
import Logo from '../../images/Logo.svg';
import Menu from '../../images/иконки/menu-burger.svg';
import Close from '../../images/иконки/Close_icon.png';
import WhatsApp from '../../images/иконки/icon-whatsapp.svg';
import Telegram from '../../images/иконки/icon-telegram.svg';

const AppHeader = () => {
    const [ showNavigate, setShowNavigate] = useState(false);
    const [width, setWidth] = useState(window.innerWidth); //стейт, в котором ширина экрана
    const breakpoint = 768; //переменная - контрольная ширина экрана
    let screenLarge = width > breakpoint;

    React.useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth); //записываем ширину экрана в стейт
         window.addEventListener("resize", handleResizeWindow); //слушатель на изменения ширины экрана
         return () => {
           window.removeEventListener("resize", handleResizeWindow);
         };
    }, []);

    const onClickBurger = () => setShowNavigate(true); //открытие навигации на телефоне или планшете
    const onClickClose = () => setShowNavigate(false); //закрытие навигации на телефоне или планшете

    return (
        <header className={Styles.header}>
            <nav className={Styles.header__navigaiton}>
                <NavLink to='/' className={Styles.logo}>
                    <img src={Logo} alt="Логотип компании" className={Styles.logo__image} />
                    <div className={Styles.logo__titleBlock}>
                        {/* Название компании разбито на две строчки с помощью двух тегов <p> */}
                        <p className={Styles.logo__text} >ШРЕЙМАН</p>
                        <p className={`${Styles.logo__text} ${Styles.logo__text_type_last}`} >Автоматизация</p>
                    </div>
                </NavLink>
                { screenLarge ? 
                    <div className={Styles.header__info} >
                        <Navigaiton show={screenLarge} />
                        <div className={Styles.contacts}>
                            <div className={Styles.contacts__socials}>
                                {/* Блок в шапке с ссылками на мессенджеры: WhatsApp и Telegram. Сейчас ссылки указывают на Сашин аккаунт, чтоб это изменить требуется:
                                    1. Взять ссылку нужного контакта через приложение или сайт месседжера
                                    2. Вставить эту ссылку в атрибут href='ссылка'
                                */}
                                <a href='https://wa.me/79137754795?text=' ><img src={WhatsApp} alt="Иконка WhatsApp" className={Styles.contacts__social}/></a>
                                <a href="https://t.me/Germech"><img src={Telegram} alt="Иконка Telegram" className={Styles.contacts__social}/></a>
                            </div>
                            <div>
                                <p className={Styles.contacts__phone} >+7 (913) 775-47-95</p> {/* На этой строчке можно изменить номер телефона компании */}
                                <span className={Styles.contacts__span} >получить консультацию</span>
                            </div>
                        </div>
                    </div> :
                    <div className={Styles.burgerMenu}>
                    <button onClick={onClickBurger} className={Styles.burgerMenu__button} >
                        <img src={Menu} alt="Иконка Бургерного меню для телофонов" className={Styles.burgerMenu__ikon} />
                    </button>
                    <div className={`${showNavigate ? Styles.burgerMenu_visible : Styles.burgerMenu_invisible}`}>
                        <button className={`${Styles.burgerMenu__button} ${Styles.burgerMenu__button_type_close}`} onClick={onClickClose}>
                            <img src={Close} alt="Иконка закрытия Бургерного меню для телофонов" className={Styles.burgerMenu__ikonClose}/>
                        </button>
                        <Navigaiton show={screenLarge} close={onClickClose} />
                    </div>
            </div> }
            </nav>
        </header>
    )
}

export default AppHeader;