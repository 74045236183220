import { 
    GET_PRODUCTS_SUCCESS, 
    GET_PRODUCTS_FAILED, 
    ADD_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    ADD_OPTION_SUCCESS,
    DELETE_OPTION_SUCCESS,
    GET_ORDER_SUCCESS, 
    GET_ORDER_FAILED,
    ADD_MORE_COUNT,
    REDUCE_COUNT,
    DELETE_ORDER
} from "../actions/actions";

import { productsObj } from "../../utils/products";
import { checkItem } from "../../utils/utils";

const initialState= {
    products: productsObj,
    productsFailed: false,

    product: [],

};

const orderState = {
  order: [],
  orderFailed: false,

  basketPopup: false,
}; 

export const productsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PRODUCTS_SUCCESS: {
        return { ...state, productsFailed: false };
      }
      case GET_PRODUCTS_FAILED: {
        return { ...state, productsFailed: true};
      }
      case ADD_PRODUCT_SUCCESS: {
        return { ...state, product: [action.item] }
      }
      case ADD_OPTION_SUCCESS: {
        return { ...state, product: [...state.product,  action.item] }
      }
      case DELETE_OPTION_SUCCESS: {
        return { ...state, product: [...state.product].filter((item) => item._id !== action.item._id)  }
      }
      default: {
        return state;
      }
    }
};

export const orderReducer = (state = orderState, action) => {
    switch (action.type) {
      case GET_ORDER_SUCCESS: {
        return { ...state, order: checkItem( state.order, action.item ) };
      }
     case GET_ORDER_FAILED: {
        return { ...state, orderFailed: true, order: [] };
      }
      case DELETE_PRODUCT_SUCCESS: {
        return { ...state, order: state.order.filter((item) => item.name !== action.name) }
      }
      case ADD_MORE_COUNT: {
        return { ...state, order: state.order.map(el => (el.name === action.name ? { ...el, count: action.count + 1} : el)) }
      }
      case REDUCE_COUNT: {
        return { ...state, order: state.order.map(el => (el.name === action.name ? { ...el, count: action.count - 1} : el)) }
      }
      case DELETE_ORDER: {
        return { ...state, order: action.order }
      }
      default: {
        return state;
      }
    }
};