import React from "react";
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import Location from "../components/location/Location";
import Styles from './NewsPage.module.css';
import { news } from '../utils/news.js';
import News from '../components/news/News.js';

const NewsPage = () => {
    const location = [ { link: "/", text: 'Главная /' }, { link: "/news", text: ' Новости' } ];

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <section className={Styles.page}>
            <Location path={location} />
            <h2 className={Styles.title} >НОВОСТИ</h2>
            <ul className={Styles.newsList} >
                {news.map(item => {
                    return (<News key={item._id} news={item} />)
                })}
            </ul>
            <ButtonFeedback />
        </section>
    )
}

export default NewsPage;