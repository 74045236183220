import Styles from './InfoItem.module.css';

const InfoItem = ({ title, textArr }) => {

    //перебор и отрисовка текстов из массива
    const returnTextArr = (start, end) => {
        return textArr.slice(start, end).map((item) => {
            return (
                <div className={Styles.item__textBlock} key={item.id}>
                    <p className={Styles.item__text}>{item.text}</p>
                    <p className={Styles.item__subtext}>{item.subtext}</p>
                </div>
            )
        })
    }

    return (
        <div className={Styles.item}>
            <h3 className={Styles.item__title}>{title}</h3>
            <div className={Styles.item__block}>
                <div className={Styles.item__description}>
                    {returnTextArr(0, 3)}
                </div>
                <div className={Styles.item__description}>
                    {returnTextArr(3, 6)}
                </div> 
            </div>
        </div>
    )
}

export default InfoItem;