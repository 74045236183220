import React from "react";
import ProductsList from "../components/productsList/ProductsList";
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import Location from "../components/location/Location";
import { useLocation } from "react-router-dom";
import Styles from "./ProductsListPage.module.css";

const ProductsListPage = () => {
    const location = useLocation();
    const locationLinks = [ 
        { link: "/", text: 'Главная /' }, 
        { link: "/catalog", text: ' Продукция /' },
    ];

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const findLocation = () => {
        switch (location.pathname) {
            case '/catalog/controlPanel': {
                return locationLinks.push({ link: "/catalog/controlPanel", text: ' Щиты управления' });
            }
            case '/catalog/autoDevice': {
                return locationLinks.push({ link: "/catalog/autoDevice", text: ' Приборы автоматики' });
            }
            case '/catalog/regulator': {
                return locationLinks.push({ link: "/catalog/regulator", text: ' Регуляторы' });
            }
            case '/catalog/mixUnits': {
                return locationLinks.push({ link: "/catalog/mixUnits", text: ' Сместительные узлы' });
            }
        }
    }

    findLocation();

    return (
        <section className={Styles.page} >
            <Location path={locationLinks} />
            <ProductsList />
            <ButtonFeedback />
        </section>
    )
}

export default ProductsListPage;