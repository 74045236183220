import Styles from './Option.module.css';
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addOptionSuccess, deleteOptionSuccess } from '../../services/actions/actions';
import { NavLink, } from "react-router-dom";

const Option = ({ el, name, description, inPopup, price }) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const product = useSelector( store => store.products.product );

    React.useEffect(() => {
        product.includes(el._id) && setChecked(true);
    },[inPopup])

    function chengeCheckbox() {
        setChecked(!checked);
    }

    //добавить юзиЭффект с очишением чекед про изменении массива "выбранные опции"

    React.useEffect(() => {
        checked ? dispatch(addOptionSuccess(el)) : dispatch(deleteOptionSuccess(el));
    }, [checked, dispatch])

    return (
        <label className={Styles.option__label} >
            <input className={Styles.option__checkbox} type="checkbox" checked={checked} onChange={chengeCheckbox} />
            <div className={Styles.option__item} >
                <img src={el.image[0]}  className={Styles.option__image} alt='Фотография опции' />
                <div className={Styles.option__text} >
                    <NavLink to={`/catalog/${el.katecory}/${el._id}`} className={Styles.option__name}><p>{name}</p></NavLink>
                    <p>{description}</p>
                </div>
                <div className={Styles.option__shop} >
                    <p className={Styles.option__price}>{price}</p>
                    <p className={`${Styles.margin_none} ${Styles.text_size_small}`}>в наличии</p>
                </div>
                <button className={Styles.option__button} onClick={() => {setChecked(!checked)}}>{!checked ? 'Выбрать' : 'Отменить'}</button>
            </div>
        </label>
    )
}

export default Option;