import React from "react";
import Location from "../components/location/Location";
import Styles from './ContactsPage.module.css';
import Address from "../components/address/Address";
import Certificates from "../components/certificates/Certificates";
import Payment from "../components/payment/Payment";

const ContactsPage = () => {
    const location = [ { link: "/", text: 'Главная /' }, { link: "/contacts", text: ' Контакты' } ];

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <section className={Styles.page}>
            <Location path={location} />
            <h2 className={Styles.title} >КОНТАКТЫ</h2>
            <Address />
            <Certificates />
            <Payment />
        </section>
    )
}

export default ContactsPage;