import React from "react";
import Styles from './HomePage.module.css';
import Promo from '../components/promo/Promo';
import StepsWork from "../components/stepsWork/StepsWork";
import Delivery from '../components/delivery/Delivery';
import Feedback from '../components/feedback/Feedback';
import InfoItem from "../components/infoItem/InfoItem";
import { advantagesList } from "../utils/utils";
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import Company from "../components/company/Company";

const HomePage = () => {

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <section className={Styles.home}>
            <Promo />
            <Company 
                title='О компании'
                text='Шрейман-Автоматизация - Российская компания, специализирующаяся на разработке систем управления и оказании услуг по монтажу автоматики и пусконаладочным работам.' 
            />
            <InfoItem
                title='Цифры и факты'
                textArr={advantagesList}
            />
            <StepsWork />
            <Delivery />
            <Feedback />
            <ButtonFeedback />
        </section>
    )
}

export default HomePage;