import Styles from './Promo.module.css';

const Promo = () => {
    return (
        <section className={`${Styles.promo} ${Styles.promo_background_img} ${Styles.promo_width_wide}`}>
            <div className={Styles.promo__textBlock}>
                <h2 className={`${Styles.promo__title} ${Styles.promo__title_place_home}`}>Автоматизируем объекты любой сложности</h2>
                <span className={Styles.promo__subtitle}>Мы - Ваш надежный партнер  в области автоматизации</span>
            </div>
        </section>
    )
}

export default Promo;