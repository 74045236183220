import React from "react";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteProductSuccess, addMoreCount, reduceCount } from '../../services/actions/actions';
import Styles from "./BasketItem.module.css";
//импорт иконок
import Plus from "../../images/иконки/4105962-add-expand-plus_113920.svg";
import Minus from "../../images/иконки/4105964-collapse-minus-reduce-remove_113927.svg";
import Del from "../../images/иконки/bin_delete_file_garbage_recycle_remove_trash_icon_123192.svg"


const BasketItem = ({ product }) => {
    const dispatch = useDispatch();
    const [count, setCount] = React.useState(product.count || 1);
    const products = useSelector( store => store.products.products );
    let katecory;

    for (var key in products) { //с помощью перебора всех продуктов находим нужную категорию, для ссылки товара на его страницу
        products[key].map(item => {
            return item.name === product.name && (katecory = key);
        });
    };

    const deleteProduct = () => { //удаление товара по названию
        dispatch(deleteProductSuccess(product.name));
    };

    const addMoreCounts = (num) => { //увеличеваем или уменьшаем количество товара в хранилище редусера для верного отображения итогов
        if ( num < 0 ) {
            setCount(count-1);
            dispatch(reduceCount(count, product.name)); 
        } else {
            setCount(count+1);
            dispatch(addMoreCount(count, product.name));
        }
    };

    return(
        <li className={Styles.item}>
            <NavLink to={`/catalog/${katecory}/${product._id}`} className={Styles.item__link_hight}>
                <img className={Styles.item__image} src={product.image[0]} alt="Фотография товара"/>
            </NavLink>
            <div className={Styles.item__description} >
                <NavLink to={`/catalog/${katecory}/${product._id}`} className={Styles.item__name} >{product.name}</NavLink>
                <p className={Styles.item__text} >{product._id}</p>
            </div>
            <div className={Styles.item__quantity}>
                <p className={Styles.item__price} >{product.price}</p>
                <div className={Styles.item__form}>
                    <button className={Styles.item__count} onClick={ () => addMoreCounts(-1) } disabled={ count === 1 ? true : false }><img src={Minus} alt="Иконка книпки минус" className={Styles.item__countIkon} /></button>
                    <p className={Styles.item__value}>{count}</p>
                    <button className={Styles.item__count} onClick={ () => addMoreCounts(1) } ><img src={Plus} alt="Иконка книпки плюс" className={Styles.item__countIkon} /></button>
                </div>
                <p className={Styles.item__price} >{(parseInt(Number(product.price.replace(/\D/g, "")) * count)).toLocaleString('ru-Ru')}</p>
            </div>
            <button className={`${Styles.basket__delButten} ${Styles.basket__delButten_position_item}`} onClick={deleteProduct}><img src={Del} alt="Иконка книпки удалить" /></button>
        </li>
    )
}

export default BasketItem;