import Styles from './Payment.module.css';

//Писать изменённый текст только меджу тегами
const Payment = () => {
    return (
        <div className={Styles.payment}>
            <div className={Styles.payment__block}>
                <h3 className={Styles.payment__title}>Оплата и доставка</h3>{/* Заголовок блока */}
                <div className={Styles.payment__textBlock}>
                    <p className={Styles.payment__text}>Мы осуществляем доставку 3 способами:</p>{/* Подзаголовок списка */}
                    <ol className={Styles.payment__list}>
                        <li className={Styles.payment__text}>1. Транспортными компаниями</li>{/* Три элемента списка, обозначенные порядковыми числами перед фразой */}
                        <li className={Styles.payment__text}>2. Курьером</li>{/* Три элемента списка, обозначенные порядковыми числами перед фразой */}
                        <li className={Styles.payment__text}>3. Лично в руки по г. Новосибирск</li>{/* Три элемента списка, обозначенные порядковыми числами перед фразой */}
                    </ol>
                    <p className={Styles.payment__text}>Стоимость доставки оценивается транспортной компанией или курьером.</p>{/* Обычный текст */}
                    <p className={Styles.payment__text}>Бесплатная доставка по г. Новосибирску</p>{/* Обычный текст */}
                </div>
            </div>
            <div className={Styles.payment__block}>
                <h3 className={Styles.payment__title}>Реквизиты</h3>{/* Заголовок блока */}
                <div className={Styles.payment__textBlock}>
                    <p className={Styles.payment__text}>ООО “Шрейман-Автоматизация”</p>{/* Подзаголовок списка */}
                    <ul className={Styles.payment__list}>
                        <li className={Styles.payment__text}>ОРГН  108217008006</li>{/* Три элемента списка, обозначенные порядковыми числами перед фразой */}
                        <li className={Styles.payment__text}>ИНН   4217108649</li>{/* Три элемента списка, обозначенные порядковыми числами перед фразой */}
                        <li className={Styles.payment__text}>КПП    541001001</li>{/* Три элемента списка, обозначенные порядковыми числами перед фразой */}
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Payment;