import React from "react";
import Product from "../components/product/Product";
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import Location from '../components/location/Location';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Styles from "./ItemPage.module.css";

const ItemPage = () => {
    let katecory;
    const locationLinks = [ 
        { link: "/", text: 'Главная /' }, 
        { link: "/catalog", text: ' Продукция /' },
    ];
    const products = useSelector( store => store.products.products );
    const location = useLocation();

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const findProduct = () => {
        switch (location.pathname.slice(0, 17)) {
            case '/catalog/controlP': {
                locationLinks.push({ link: "/catalog/controlPanel", text: ' Щиты управления /' });
                katecory = 'controlPanel';
                return products.controlPanel.find(item => item._id === location.pathname.slice(-4))
            }
            case '/catalog/autoDevi': {
                locationLinks.push({ link: "/catalog/autoDevice", text: ' Приборы автоматики /' });
                katecory = 'autoDevice';
                return products.autoDevice.find(item => item._id === location.pathname.slice(-4))
            }
            case '/catalog/regulato': {
                locationLinks.push({ link: "/catalog/regulator", text: ' Регуляторы /' });
                katecory = 'regulator';
                return products.autoDevice.find(item => item._id === location.pathname.slice(-4))
            }
            case '/catalog/mixUnits': {
                locationLinks.push({ link: "/catalog/mixUnits", text: ' Смесительные узлы /' });
                katecory = 'mixUnits';
                return products.autoDevice.find(item => item._id === location.pathname.slice(-4))
            }
            default: {
                return products;
            }
        }
    };

    const product = findProduct();
    locationLinks.push({ link: `/catalog/${katecory}/${product._id}`, text: ` ${product.name}` });

    return (
        <section className={Styles.page}>
            <Location path={locationLinks} />
            <Product product={product} />
            <ButtonFeedback />
        </section>
    )
}

export default ItemPage;