import Styles from './Certificates.module.css';
// Чтоб поменять сертификаты надо просто указать путь до фотографии нужного сертификата. Оба сертификата на двух строчках ниже
import First from '../../images/сертификаты/ctn_pages-to-jpg-0001.jpg';
import Second from '../../images/сертификаты/ctn_pages-to-jpg-0002.jpg';

const Certificates = () => {
    return (
        <div className={Styles.certificates}>
            <h3 className={Styles.certificates__title} >Сертификаты</h3>
            <ul className={Styles.certificates__list} >
                <li><img src={First} className={Styles.certificates__item} alt='Первый сертификат' /></li>
                <li><img src={Second} className={Styles.certificates__item} alt='Второй сертификат' /></li>
            </ul>
        </div>
    )
}

export default Certificates;