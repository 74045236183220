import React from "react";
import Location from "../components/location/Location";
import CatalogItems from "../components/catalog/Catalog";
import ButtonFeedback from "../components/buttonFeedback/ButtonFeedback";
import Styles from "./CatalogPage.module.css";

const CatalogPage = () => {
    const location = [ { link: "/", text: 'Главная /' }, { link: "/catalog", text: ' Продукция' } ];

    React.useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <section className={Styles.catalog} >
            <Location path={location} />
            <CatalogItems />
            <ButtonFeedback />
        </section>
    )
}

export default CatalogPage;